import React, { useRef } from "react";
import { useLoader, useFrame, extend, useThree } from "react-three-fiber";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from 'three'

extend({ OrbitControls });

const Model = props => {
    const model = useLoader(
        GLTFLoader,
        props.path
    )

    // animation part
    let mixer
    if (model.animations.length) {
        mixer = new THREE.AnimationMixer(model.scene);
        model.animations.forEach(clip => {
            const action = mixer.clipAction(clip)
            action.timeScale = .2
            action.play();
        });
    }

    useFrame((state, delta) => {
        mixer?.update(delta)
    })

    model.scene.traverse(child => {
        if (child.isMesh) {
            child.castShadow = false
            child.receiveShadow = false
        }
    })

    const CameraControls = () => {
      const {
        camera,
        gl: { domElement },
      } = useThree();
    
      // Ref to the controls, so that we can update them on every frame using useFrame
      const controls = useRef();
      useFrame((state) => controls.current.update());
      return (
        <orbitControls
          ref={controls}
          args={[camera, domElement]}
          enableZoom={true}
          maxAzimuthAngle={Math.PI}
          maxPolarAngle={Math.PI}
          minAzimuthAngle={-Math.PI}
          minPolarAngle={-Math.PI}
          targetSet={0,0,0}
          autoRotate={false}
          minDistance={0}
          maxDistance={24}
        />
      );
    }
    
    return (
      <>
        <CameraControls />
        <hemisphereLight intensity={10} position={ 0, 0, -5 } />
        <primitive 
            object={model.scene}
            position={[0,1,5]}
        />
      </>
    )
}

export default Model;